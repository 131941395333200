.aboutUs-heading {
  color: #ffff;

  font-size: 50px;
  font-weight: 200;
  text-align: center;
  margin: 70px 0px;
}
.about-content {
  display: flex;
  justify-content: center;
   align-items: center;
  flex-direction: column;
  
}
.about-content >p {
  max-width: 90%;
  font-size: 15px;
}


.about-second-content {
  padding: 10px;
  display: flex;
}

.second-section {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0px;
  padding: 5px;
  margin: 10px;
  margin-top: 70px;
  background-color: #15172e;
}
.second-section-left {
  padding: 10px;
  width: 100%;
  gap: 80px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  color: #ffff;
}
.second-section-left-text {
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 10px;
}
.second-section-leading-text {
  max-width: 80%;
}
.second-section-badgetext {
  color: #5594fb;
  font-weight: 500;
}
.second-section-right {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  gap: 50px;
  
}
.third-section-left{
    display: flex;
    flex-direction: row;
   gap: 50px;
   justify-content: left;
   flex-wrap: wrap;
  
}

@media screen and (min-width: 769px) {
  .about-content >p {
    max-width: 60%;
    font-size: 20px;
  }
  .second-section-left-text {
    font-size: 40px;
  }
  .second {
    padding: 70px;
    margin: 125px;
    background-color: #15172e;
  }
 .second-section-left {
  padding: 10px;
  width: 50%;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  color: #ffff;
}
  .second-section {
    flex-wrap: nowrap;
    padding: 170px;
  }
  .third-section-left{
    flex-wrap: nowrap;
  }
  .second-section-right {
    width: 50%;
    min-width: 156px;
    
  }
  
}

@media screen and (min-width: 992px) {
  .about-content {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 60px;
  }
}
