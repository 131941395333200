.header{
    min-height:100vh;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.5)), url("../../assets/images/heroBackground.png") center/cover no-repeat;
  
    position: relative;
    justify-content: center;
    
   
}
.header-content .text-lead{
    margin: 3.2rem 0 4.9rem 0;
   
}

.header-title{
    font-size: 25px;
    font-weight: 400;
    line-height: 1.2;
    color: #ffff;

}
.header-btn{
    align-self: center!important;

}


@media screen and (min-width: 600px){
    .header-title{
        font-size: 50px;
    }
    .header{
        min-height: 150vh;
        justify-content: center;
    }
  
}