.item{
    border: 1px solid white;
    margin-top: 10px;
}
.features .item-icon{
    transition: var(--transition);
}
.features .item-head{
    padding: 18px 28px;
    cursor: pointer;
}
.features .item-head-border{
    border-bottom: 2px solid var(--clr-robin-blue);
}
.features .item-body{
    margin-top: 16px;
    max-height: 0;
    overflow: hidden;
    transition: all 500ms linear;
    padding:0px 25px;
}
.features .item-body .text{
    padding-bottom: 16px;
}
.features .item-body .text{
    padding-bottom: 16px;
}
.features .item-body-show{
    max-height: 200px;
}
.features .item-icon-rotate{
    transform: rotate(45deg);
}
.cardtitle {
    position: absolute;
    bottom: 0px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 100%;
}