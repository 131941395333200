.contact .contact-content{
    row-gap: 60px;
    background-color: rgba(0, 0, 0, 0.295);
    padding: 20px;
}
.contact .form-control{
    height: 56px;
    border: 1px solid #555;
    border-radius: 4px;
    background-color: var(--clr-white);
    width: 100%;
    margin-bottom: 0;
    color: var(--clr-black);
    font-family: inherit;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
}
.contactBlockInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.contact-left{
    padding: 0px 50px;
}
.mapbutton{
    bottom: 21px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
}
.mapButton{
    
    padding: 5px;
    background-color: red;
}
.map-container{
    position: relative;
}
.contact .form-control-text{
    display: block;
    font-size: 16px;
    color: var(--clr-white);
    text-align: left;
    margin-bottom: 20px;
    margin-top: 6px;
}
.contact .submit-btn{
    background-color: var(--clr-robin-blue);
    color: var(--clr-white);
    text-transform: uppercase;
    padding: 12px 28px;
    letter-spacing: 1px;
    border-radius: 3px;
    margin-top: 10px;
    text-align: center;
}
.contact .submit-btn:hover{
    background: rgb(87, 100, 222);
    background: linear-gradient(45deg, rgba(87, 100, 222, 1) 0%, rgba(85, 179, 213, 1) 100%);
}
.contact .map-content{
    margin-top: 80px;
    filter: grayscale(89%) invert(140%) hue-rotate(35deg) contrast(82%) saturate(12) sepia(28%);
}
.contact_container{
    padding: 10px;
    background-image: url("./../../assets//images/Contact\ Us\ backgound.png");
}


.info{
    margin-top: -10px;
}
.info .item{
    padding: 40px;
    cursor: pointer;
    transition: var(--transition);
    position: relative;
}
.info .item::after{
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0%;
    height: 5px;
    background: rgb(87, 100, 222);
    background: linear-gradient(45deg, rgba(87, 100, 222, 1) 0%, rgba(85, 179, 213, 1) 100%);
    transition: var(--transition);
}
.info .item:hover::after{
    width: 100%;
}
.info .item-info-text{
    font-size: 20px;

    margin-bottom: 10px;
}
.info .item-icon{
    font-size: 45px;
}
.socialMediaIconsContainer{
    font-size: 35px;
    justify-content: center;
     display: flex;
     gap: 10px;
     margin: 15px 0px;
}
.info .item-list{
    row-gap: 30px;
   
}
.info .item-link{
    margin-top: 18px;
    display: inline-block;
    transition: var(--transition);
}
.info .item:hover .item-link{
    color: var(--clr-white);
}

@media screen and (min-width: 768px){
    .info .item-list{
        grid-template-columns: repeat(2, 1fr);
        column-gap: 30px;
    }
    .contact_container{
        padding: 150px;
    }
    .socialMediaIconsContainer{
        justify-content: left;
    }
}

@media screen and (min-width: 1200px){
    .info .item-list{
        grid-template-columns: repeat(3, 1fr);
        column-gap: 30px;
    }
}

@media screen and (min-width: 992px){
    .contact .contact-content{
        grid-template-columns: repeat(2, 1fr);
        column-gap: 40px;
        row-gap: 0;
        align-items: center;
    }

}